<template>
  <div class="brain-popover" v-if="showPopover">
    <div class="popover-mask"></div>
    <div class="popover-content">
      <div class="popover-head">
        <span class="popover-title">{{ $t("student.add_student") }}</span>
      </div>
      <div class="popover-body student-popover">
        <el-form
          :model="student"
          :rules="rules"
          ref="modelForm"
          label-width="140px"
          size="small"
        >
          <el-form-item
            :label="$t('student.student') + '：'"
            prop="student_name"
          >
            <el-input v-model="student.student_name"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('student.department') + '：'"
            prop="department_id"
          >
            <el-select
              v-model="student.department_id"
              @change="selectDepartment"
              placeholder=" "
            >
              <el-option
                v-for="item in departmentList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('student.doctor') + '：'" prop="doctor_id">
            <el-select
              v-model="student.doctor_id"
              @change="selectDoctor"
              placeholder=" "
            >
              <el-option
                v-for="item in doctorList"
                :key="item.id"
                :label="item.doctor_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('student.email') + '：'" prop="email">
            <el-input v-model="student.email"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('student.init_password') + '：'"
            prop="password"
          >
            <el-input type="password" v-model="student.password"></el-input>
          </el-form-item>
          <el-form-item :label="$t('student.username') + '：'" prop="username">
            <el-input v-model="student.username"></el-input>
          </el-form-item>
          <el-form-item :label="$t('student.tel_number') + '：'">
            <el-input v-model="student.telephone"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="popover-footer text-right">
        <button
          type="button"
          class="btn btn-grey btn-sm ml10"
          @click.stop="closePopover"
        >
          {{ $t("cancel") }}
        </button>
        <button
          type="button"
          class="btn btn-primary btn-sm ml10"
          @click.stop="submitStudent('modelForm')"
        >
          {{ $t("save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { departmentListRequest, doctorListRequest } from "../../api/api";

export default {
  props: {
    student: {
      type: Object,
      default: function () {
        return {};
      },
    },
    showPopover: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      departmentList: [],
      doctorList: [],
    };
  },
  computed: {
    rules() {
      return {
        student_name: [
          {
            required: true,
            message: this.$t("validate.student"),
            trigger: "change",
          },
        ],
        department_id: [
          {
            required: true,
            message: this.$t("validate.department"),
            trigger: "change",
          },
        ],
        doctor_id: [
          {
            required: true,
            message: this.$t("validate.doctor"),
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: this.$t("validate.email"),
            trigger: "change",
          },
        ],
        password: [
          {
            required: true,
            message: this.$t("validate.init_password"),
            trigger: "change",
          },
        ],
        username: [{ required: true, message: " ", trigger: "change" }],
      };
    },
  },
  watch: {
    showPopover: function (val) {
      if (val === true) {
        this.getDepartmentList();
        this.getDoctorData(this.student.department_id);
      }
    },
  },
  mounted() {},
  methods: {
    submitStudent(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("submitStudent", this.student);
        } else {
          return false;
        }
      });
    },
    closePopover() {
      this.$emit("closePopover");
    },
    async getDepartmentList() {
      let params = {
        page: 1,
        pageSize: 1000000,
      };
      try {
        let res = await departmentListRequest(params);
        if (res.status === 200) {
          this.departmentList = res.data.length > 0 ? res.data : [];
        } else {
          console.log(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getDoctorData(departmentId) {
      let params = {
        department_id: departmentId,
        page: 1,
        pageSize: 100000,
      };
      try {
        let res = await doctorListRequest(params);
        if (res.status === 200) {
          this.doctorList = res.data.length > 0 ? res.data : [];
        } else {
          this.$helper.errNotify(res.message);
        }
      } catch (error) {
        this.$helper.errNotify(error.message);
      }
    },
    selectDepartment: function (val) {
      this.getDoctorData(val);
      this.student.doctor_id = "";
    },
    selectDoctor: function (val) {
      this.student.doctor_id = val;
    },
  },
};
</script>

<style lang="less">
.student-popover {
  .el-select {
    width: 100%;
  }
  label {
    margin-bottom: 0;
  }
}
</style>
