<template>
  <div class="student-manage">
    <div class="main-content">
      <div class="page-title clearfix">
        <div class="float-left">
          <span>{{ $t("nav.student") }}</span>
          <span class="total-num">{{ totalCount }}</span>
        </div>
        <div class="float-right">
          <button
            type="button"
            class="btn btn-danger btn-sm"
            @click="showAddStudentPopover"
          >
            {{ $t("student.add_student") }}
          </button>
          <!-- <button type="button" class="btn btn-primary btn-sm ml10" @click.stop="handleShowManagerPopover">批量设置权限</button> -->
        </div>
      </div>
      <div class="student-content">
        <div class="search-wrapper">
          <span class="search-btn" @click.stop="handleSearch">{{
            $t("search")
          }}</span>
          <el-form
            :inline="true"
            :model="searchForm"
            class="search-form"
            size="small"
          >
            <el-form-item :label="$t('doctor.department')">
              <el-select
                v-model="searchForm.department_id"
                @change="getStudentData"
                clearable
                placeholder=""
              >
                <el-option
                  v-for="item in departmentList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('student.doctor')">
              <el-input
                v-model="searchForm.doctor_name"
                clearable
                placeholder=" "
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('student.student')">
              <el-input
                v-model="searchForm.student_name"
                clearable
                placeholder=" "
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="tabel-content">
          <el-table
            ref="multipleTable"
            :data="studentList"
            :empty-text="$t('no_data')"
            style="width: 100%"
            header-row-class-name="thead-dark"
          >
            <el-table-column
              prop="student_name"
              :label="$t('student.student') + '(' + $t('sign.username') + ')'"
            >
              <template v-slot="scope">
                <span>
                  {{ scope.row.student_name }} ({{ scope.row.username }})
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="doctor_name"
              :label="$t('student.doctor') + '(' + $t('sign.username') + ')'"
            >
              <template v-slot="scope">
                <span>
                  {{ scope.row.doctor_name }} ({{ scope.row.doctor_username }})
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="department_name"
              :label="$t('student.department')"
            ></el-table-column>
            <el-table-column
              prop="project_count"
              :label="$t('student.project_num')"
            ></el-table-column>
            <el-table-column
              prop="patient_count"
              :label="$t('student.patient_num')"
            ></el-table-column>
            <el-table-column
              prop="date_updated"
              :label="$t('student.update_time')"
            ></el-table-column>
            <el-table-column :label="$t('student.status')">
              <template v-slot="scope">
                <span>{{ scope.row.status | formatStatus }}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column :label="$t('student.right')">
              <template v-slot="scope">
                <router-link
                  :to="{ name: 'studentAuthority', query: { id: scope.row.id, name: scope.row.student_name, doctor: scope.row.doctor_name, department: scope.row.department_name } }"
                  >{{ $t("student.edit_right") }}</router-link
                >
              </template>
            </el-table-column> -->
            <el-table-column :label="$t('operate')" align="center" width="150">
              <template v-slot="scope">
                <span
                  class="cursor text-primary mr10"
                  @click="showEditStudentPopover(scope.row)"
                  >{{ $t("student.modify") }}</span
                >
                <span
                  class="cursor text-danger"
                  @click="handleDeleteStudent(scope.row.id)"
                  >{{ $t("delete") }}</span
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="clearfix mt-3 mb-3">
        <div class="float-left">
          <pagination
            v-if="totalCount > 0"
            :pageSize="pageSize"
            :layout="layout"
            :currentPage="currentPage"
            :total="totalCount"
            @goToPage="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <!-- <div class="brain-popover permission-popover" v-if="showManagerPopover">
      <div class="popover-mask"></div>
      <div class="popover-content">
        <div class="popover-head">批量设置数据负责人</div>
        <div class="popover-body">
          <form>
            <div class="form-group row">
              <label
                for="staticEmail"
                class="col-sm-3 col-form-label text-right"
                ><span class="text-danger">*</span>Email</label
              >
              <div class="col-sm-9">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext"
                  id="staticEmail"
                  value="email@example.com"
                />
              </div>
            </div>
            <div class="form-group row">
              <label
                for="inputPassword"
                class="col-sm-3 col-form-label text-right"
                ><span class="text-danger">*</span>Password</label
              >
              <div class="col-sm-9">
                <input
                  type="password"
                  class="form-control"
                  id="inputPassword"
                  placeholder="Password"
                />
              </div>
            </div>
          </form>
        </div>
        <div class="popover-footer text-right">
          <button
            type="button"
            class="btn btn-grey btn-sm ml10"
            @click.stop="handleHideManagerPover"
          >
            {{ $t("close") }}
          </button>
          <button type="button" class="btn btn-primary btn-sm ml10">
            {{ $t("confirm") }}
          </button>
        </div>
      </div>
    </div> -->
    <add-model
      :showPopover="showAddPopover"
      @closePopover="closePopover"
      :student="student"
      @submitStudent="submitStudent"
    >
    </add-model>
    <edit-model
      :showPopover="showEditPopover"
      :student="student"
      @closePopover="closePopover"
      @submitStudent="submitStudent"
    >
    </edit-model>
  </div>
</template>

<script>
import {
  getStudentList,
  addStudentRequest,
  editStudentRequest,
  deleteStudent,
  departmentListRequest,
} from "../../api/api";
import addModel from "../../components/student/addModel";
import editModel from "../../components/student/editModel";
import pagination from "../../components/pager";
import { paginationMixin } from "../../utils/mixin";
import { MessageBox, Loading } from "element-ui";
import { mapState, mapGetters } from "vuex";

let _that;

export default {
  mixins: [paginationMixin],
  components: {
    addModel,
    editModel,
    pagination,
  },
  data() {
    return {
      searchForm: {
        student_name: "",
        doctor_name: "",
        department_id: "",
      },
      studentList: [],
      departmentList: [],
      showEditPopover: false,
      showAddPopover: false,
      student: {},
      popoverType: "",
      showSearchDrawer: false,
      clearable: false,
      showManagerPopover: false,
      pageSize: 15,
      currentPage: 1,
      totalCount: 0,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  created() {
    this.getStudentData();
    this.getDepartmentList();
  },
  methods: {
    handleSearch() {
      this.currentPage = 1;
      this.pageSize = 15;
      this.getStudentData();
    },
    showSearchWrapper() {
      this.showSearchDrawer = true;
    },
    hideSearchWrapper() {
      this.showSearchDrawer = false;
    },
    async getStudentData() {
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });
      let params = {
        ...this.searchForm,
        date_updated_start: "",
        date_updated_end: "",
        page: this.currentPage,
        pageSize: this.pageSize,
      };
      try {
        let res = await getStudentList(params);
        if (res.status === 200) {
          this.studentList = res.data.length > 0 ? res.data : [];
          this.totalCount = res.total || 0;
        } else {
          this.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    async getDepartmentList() {
      let params = {
        page: 1,
        pageSize: 1000000,
      };
      try {
        let res = await departmentListRequest(params);
        if (res.status === 200) {
          this.departmentList = res.data.length > 0 ? res.data : [];
        }
      } catch (error) {
        console.log(error);
      }
    },
    showAddStudentPopover() {
      this.student = {
        student_name: "",
        department_id: this.user.department_id || "",
        doctor_id: this.user.role == 2 ? this.user.id : "",
        email: "",
        password: "",
        username: "",
        telephone: "",
      };
      this.showAddPopover = true;
      this.popoverType = "add";
    },
    showEditStudentPopover(student) {
      this.student = {
        id: student.id || "",
        student_name: student.student_name || "",
        department_id: student.department_id || "",
        doctor_id: student.doctor_id || "",
        email: student.email || "",
        password: student.password || "",
        username: student.username || "",
        telephone: student.telephone || "",
      };
      this.showEditPopover = true;
      this.popoverType = "edit";
    },
    closePopover() {
      this.student = {};
      this.showEditPopover = false;
      this.showAddPopover = false;
      this.popoverType = "";
    },
    submitStudent(student) {
      if (this.popoverType === "add") {
        this.handleAddStudent(student);
      } else if (this.popoverType === "edit") {
        this.handleEditStudent(student);
      }
    },
    async handleAddStudent(student) {
      let _self = this;
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });
      try {
        let res = await addStudentRequest(student);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          this.getStudentData();
          this.closePopover();
        } else {
          this.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    async handleEditStudent(student) {
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });

      let params = {
        id: student.id,
        department_id: student.department_id,
        doctor_id: student.doctor_id,
        student_name: student.student_name,
        email: student.email,
        password: student.password,
        username: student.username,
        telephone: student.telephone,
      };
      try {
        let res = await editStudentRequest(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          this.getStudentData();
          this.closePopover();
        } else {
          this.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    handleDeleteStudent(id) {
      MessageBox.confirm(this.$t("notice.p_confirm"), this.$t("notice.tips"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(() => {
          this.deleteStudent(id);
        })
        .catch(() => {});
    },
    async deleteStudent(id) {
      let loadingInstance = Loading.service({
        background: "rgba(0, 0, 0, 0.7)",
      });
      try {
        let res = await deleteStudent({ id: id });
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          this.getStudentData();
        } else {
          this.$helper.errNotify(res.message);
        }
        loadingInstance.close();
      } catch (error) {
        loadingInstance.close();
        this.$helper.errNotify(error.message);
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getStudentData();
    },
    handleShowManagerPopover() {
      this.showManagerPopover = true;
    },
    handleHideManagerPover() {
      this.showManagerPopover = false;
    },
  },
  beforeCreate() {
    _that = this;
  },
  filters: {
    formatStatus: function (status) {
      switch (status) {
        case "0":
          return _that.$t("student.close");
        case "1":
          return _that.$t("student.open");
        default:
          return "";
      }
    },
  },
};
</script>

<style lang="less">
.table {
  border-bottom: 1px solid #dee2e6;
}
.student-manage {
  .el-checkbox {
    margin-bottom: 0;
    color: #fff;
  }
  .form-item {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
